
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-main {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  background-image: url("./images/background.svg");
  background-size: 100% 150%;
  background-position: center;
  background-repeat: no-repeat;
}

p {
  font-size: min(calc(10px + 2vmin), 18px);
}

.App-link {
  color: #61dafb;
}

.purple {
  color: #7000ff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

span.bar {
  height: 2px;
  width: 28px;
  margin-top: 4px;
  display: block;
  background-color: white;
}
